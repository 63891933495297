import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import BlogPostPreview from "./blog-post-preview";

const LatestPosts = (props) => {
  return (
    <StaticQuery
      query={latestPostsQuery}
      render={(data) => {
        const posts = data.posts?.edges || [];
        return (
          <section className="container mx-auto my-4">
            <h2 className="w-full my-4 text-2xl xl:text-4xl font-bold leading-tight text-center text-gray-800">
              Blog
            </h2>

            <div className="flex flex-wrap">
              {posts.map(({ node }) => {
                return (
                  <div key={node.id} className="flex flex-col p-2 w-full xl:w-1/3">
                    <BlogPostPreview maxImageWidth={412} {...node} />
                  </div>
                );
              })}
            </div>
          </section>
        );
      }}
    />
  );
};

LatestPosts.propTypes = {};

const latestPostsQuery = graphql`
  query LatestPostQuery {
    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

export default LatestPosts;
