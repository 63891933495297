import React from "react";
import { graphql } from "gatsby";
import { useEffect } from "react";

const GGSearchSection = (props) => {
  useEffect(() => {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://cse.google.com/cse.js?cx=003094707974941455361:fcmisrg4ofy";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "gg-search-sdk");
  });
  return (
    <div className="container mx-auto" style={{ minHeight: 600 }}>
      <div className="gcse-search"></div>
    </div>
  );
};

export default GGSearchSection;
