import React from "react";
import PropTypes from "prop-types";
import PortableText from "./portableText";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

const ProductList = ({ title, ...props }) => {
  const className = props.rows.length;
  return (
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-12 mx-auto">
        <div className="w-full mb-4">
          <h1 className="title-font sm:text-3xl text-2xl font-medium text-blue-900 uppercase">
            {title}
          </h1>
        </div>
        <div className="flex flex-wrap -m-4">
          {props.rows.map((item) => {
            const { title, text, illustration } = item;
            let href = "/",
              img = null;
            if (
              illustration &&
              illustration.disabled !== true &&
              illustration.image &&
              illustration.image.asset
            ) {
              const fluidProps = getFluidGatsbyImage(
                illustration.image.asset._id,
                { maxWidth: 250 },
                clientConfig.sanity
              );

              img = (
                <img
                  className="flex-shrink-0 object-cover object-center sm:mb-0 mb-2"
                  src={fluidProps.src}
                  alt={illustration.image.alt}
                />
              );
              href = illustration.image.caption;
            }
            return (
              <div key={item._key} className="p-4 lg:w-1/2">
                <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                  {img}
                  <div className="flex-grow sm:pl-4">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      <a href={href} title={title}>
                        {title}
                      </a>
                    </h2>
                    <div className="mb-2 text-justify">
                      <PortableText blocks={text} />
                    </div>
                    <a
                      className="inline-flex items-center text-gray-100 bg-blue-700 border-0 py-1 px-3 focus:outline-none hover:bg-blue-600 text-sm tracking-tight"
                      href={href}
                    >
                      Xem tiếp
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

ProductList.propTypes = {};

export default ProductList;
