import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MainImage from "./MainImage";

const MainBanner = ({ className, ...props }) => {
  const { banner } = props;

  return (
    <div className={classNames("main-banner mb-8", className)}>
      <MainImage className="w-full" mainImage={banner} />
    </div>
  );
};

MainBanner.propTypes = {
  className: PropTypes.string,
};

export default MainBanner;
