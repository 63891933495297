import React from "react";
import PropTypes from "prop-types";
import MessengerIcon from "./icon/messenger";
import PhoneIcon from "./icon/phone";

const PhonesList = (props) => {
  return (
    <div className="container mx-auto flex flex-wrap w-full justify-between my-2 lg:my-4">
      <a
        href="https://m.me/capdiensaigon2020"
        target="_blank"
        rel="nofollow"
        className="inline-flex w-full justify-center xl:w-auto items-center text-white bg-blue-500 border-0 my-2 py-2 px-3 focus:outline-none hover:bg-blue-600 rounded text-lg"
      >
        <MessengerIcon className="mr-2" />
        Messenger
      </a>

      {[
        { label: "0984 33 6000", phone: "0984336000" },
        { label: "0979 46 55 77", phone: "0979465577" },
        { label: "090 333 0291", phone: "0903330291" },
      ].map(({ label, phone }) => (
        <a
          key={phone}
          href={`tel:${phone}`}
          className="inline-flex w-full justify-center xl:w-auto items-center text-gray-100 bg-red-700 border-0 my-2 py-2 px-3 focus:outline-none hover:bg-red-600 rounded text-lg"
          title={`Gọi tới số Hotline: ${label}`}
        >
          <PhoneIcon className="mr-2" />
          {label}
        </a>
      ))}
    </div>
  );
};

PhonesList.propTypes = {};

export default PhonesList;
