import React from "react";
import PropTypes from "prop-types";

const ContactInfo = (props) => {
  return (
    <div className="">
      <iframe
        src="https://www.google.com/maps/d/embed?mid=15MHPHSBFU9kFIq642VCqWkBtqV6Ln4ka"
        width="100%"
        height="450"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/2">
          <iframe
            width="100%"
            height="590"
            src="https://47a6264b.sibforms.com/serve/MUIEAOiNnBpHfHiUQKQ8x6w_KBdTHwInwILarp2FD2DpVZudx806B0KtysLbAjagwtJVhmX6nC9cYkULKMpaj8BYkcJJoLHcZeHc6IhKwBqScNhrWGfeBHfaxy8l5kPiMKSHaP0cdg_vP9LqIZyWijNyYcdqVvP0nTzrNLMIB93oy6A6lhl3ppR1_KuMFu8QtfsmtkfVNqAGouXZ"
            frameborder="0"
            scrolling="auto"
            allowfullscreen=""
            style={{
              display: "block",
              maxWidth: "100%",
            }}
          ></iframe>
        </div>

        <div className="w-full lg:w-1/2">
          <div className="px-8 py-4">
            <p>
              <br />
              Nguyễn Đức Trọng - Trưởng phòng kinh doanh
              <br />
              Điện thoại:{" "}
              <a className="hover:underline" href="tel:0979465577" title="Gọi tới số 097 946 5577">
                097 946 5577
              </a>{" "}
              (Zalo)
              <br />
              Email:{" "}
              <a
                className="hover:underline"
                href="mailto:info@capdiensaigon.com"
                title="Gửi mail tới địa chỉ info@capdiensaigon.com"
              >
                info@capdiensaigon.com
              </a>
              <br />
              <br />
              Đỗ Hoài Nhân - Trợ lý kinh doanh
              <br />
              Điện thoại:{" "}
              <a className="hover:underline" href="tel:0903330291" title="Gọi tới số 090 333 0291">
                090 333 0291
              </a>{" "}
              (Zalo)
              <br />
              <br />
              Công ty TNHH MTV Sangjin Việt Nam
              <br />
              MST: 0315490936
              <br />
              <br />
              Công ty TNHH Sài Gòn Cable
              <br />
              MST: 0313781425
              <br />
              Địa chỉ:{" "}
              <a
                className="hover:underline"
                title="Địa chỉ công ty"
                href="https://www.google.com/maps/place/15+%C4%90%C6%B0%E1%BB%9Dng+s%E1%BB%91+40,+P.+B%C3%ACnh+An,+Qu%E1%BA%ADn+2,+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam/@10.7867147,106.7241811,17z/data=!3m1!4b1!4m5!3m4!1s0x317525fe34df8c2f:0x80ca20614853eb39!8m2!3d10.7867147!4d106.7263698"
              >
                15 Đường 40, P. An Khánh, Thành phố Thủ Đức, Hồ Chí Minh
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactInfo.propTypes = {};

export default ContactInfo;
