import React from "react";
import PropTypes from "prop-types";

const PagesList = ({ className }) => {
  return <div></div>;
};

PagesList.propTypes = {
  className: PropTypes.string,
};

export default PagesList;
