import React from "react";
import PropTypes from "prop-types";
import ChevronRight from "./icon/chevron-right";

const Breadcrumb = ({ items, currentTitle }) => {
  return (
    <nav className="container mx-auto text-black font-bold mt-20 mb-4 p-2" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex flex-wrap">
        {items.map((item) => {
          return (
            <li key={item._key} className="flex items-center">
              <a href={item.route}>{item.title}</a>
              <ChevronRight />
            </li>
          );
        })}
        <li key="current-title">
          <span className="text-gray-500" aria-current="page">
            {currentTitle}
          </span>
        </li>
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {};

export default Breadcrumb;
